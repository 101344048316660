define('fusion/private/module-base',['require','knockout','fusion/private/tea','fusion/private/cache','fusion/validation','fusion/navigation','fusion/jquery','fusion/utils','fusion/log','fusion/event'],function (require) {
    "use strict";

    var ko = require('knockout');

    function ModuleBase(moduleId) {
        var m = this;
        var parentModule = null;

        m.t0 = new Date().getTime();
        m.t1 = null;


        m.events = {
            pageBlock: "pageBlock",
            darkMode: "darkMode",
            pullToRefresh : "pullToRefresh"
        };

        //module is the "super" module, or the module of the derived view model
        if (!moduleId) {
            throw new Error("moduleId parameter must not be null or undefined in ModuleBase()");
        }

        m.getBase = function () {
            //TODO: put check here to help ensure that this function is called in correct context 
            //  (e.g. so that `this` refers to derived instance)
            var p = Object.getPrototypeOf(this);
            return p;
        };

        //expose moduleId so it can be accessed as needed
        m.moduleId = moduleId;

        var tea = require('fusion/private/tea');
        var cache = require('fusion/private/cache');
        var validation = require('fusion/validation');
        var $navigation = require('fusion/navigation');
        var $ = require("fusion/jquery");
        //var durandalApp = require("durandal/app");

        var utils = require('fusion/utils');
        var log = require('fusion/log');
        //var analytics = require("fusion/private/analytics");


        m.pageMessageHtml = ko.observable();//holds any page message that applies to this view model.

        var evnt = require("fusion/event");

        //enum of error types that can be returned
        var errorType = {
            cachedParameterNotFound: "Cached Parameter Not Found",
            unwrapArgument: "Unknown error while unwrapping one or parameters"
        };

        var activateArguments = null;


        //this is the activate function that Durandal will call
        m.activate = function () {

            //setting `m=this` provides backward compatibility for old style factory methods.  
            //  It works for view models because this is the first call the Durandal makes when activating
            //  It will not work for models, or view models that don't have this function called in the right context.
            //if (m != this) debugger;
            m = this;

            //clear the page message
            m.setPageMessageHtml("");


            //when only the hash portion of the location changes, the canActivate doesn't fire again.
            //so, check to make sure their both or neither aguments and unwrappedArguments are null
            //  if not, then we need to upwrap it from here.
            if ((activateArguments === null) !== (arguments === null)) {
                activateArguments = unwrapArguments.call(m, arguments);
            }

            //call activate
            try {

                loadAppletCss();

                if (m.onActivate) {     // if the developer has created an onActivate method it will be called here
                    // publishing the VM
                    evnt.publish('viewModel:activate', m);

                    return m.onActivate.apply(m, activateArguments || []);
                } else {
                    return true;
                }
            }
            catch (ex) {
                log.error("Error in " + moduleId + ": " + ex.message);
                throw (ex)
            }
            finally {
                activateArguments = null;
                //analytics.pageView();
                evnt.publish("routeChange", { "eventName": "Base-PageView", "routeObject": location.href });
            }
        };

        //this is the canActivate function that Durandal will call
        m.canActivate = function () {

            //setting `m=this` provides backward compatibility for old style factory methods.  
            //  It works for view models because this is usually the first call the Durandal makes when activating
            //  It will not work for models, or view models that don't have this function called in the right context.
            m = this;

            //unwrap arguments and set to module-level activateArguments variable
            activateArguments = unwrapArguments.call(m, arguments);

            if (!(activateArguments instanceof Array)) {
                var onActivateErrorResponse = activateArguments;
                return onActivateErrorResponse;
            }
            else {
                if (m.onCanActivate) {
                    return m.onCanActivate.apply(m, activateArguments || []);
                }
                else {
                    return true;
                }
            }

            //            });

        };

        m.attached = function () {

            // ensuring that a view load scrolls to the top of the page
            if (fusion.cordova.isCordova && $(".fusion-inner-wrap").length) {
                $('.fusion-inner-wrap').scrollTo(0);
            }
            else {
                $('html,body').scrollTo(0);
            }


            try {

                //todo: execute any custom FX logic here, if any

                //call the developer's hook
                if (m.onAttached) {
                    m.onAttached.apply(m, arguments);
                }

            } catch (ex) {
                throw new Error("Failed to attach module " + m.moduleId + ": " + ex.stack || ex);
            }
        };

        m.detached = function () {
            try {

                if (m.onDetached) {
                    m.onDetached.apply(m, arguments);
                }
            } catch (ex) {
                throw new Error("Failed to detach module " + m.moduleId + ": " + ex.stack || ex);
            }
        }

        m.deactivate = function () {
            try {

                if (m.onDeactivate) {
                    m.onDeactivate.apply(m, arguments);
                }
            } catch (ex) {
                throw new Error("Failed to deactivate module " + m.moduleId + ": " + ex.stack || ex);
            }
        };


        m.compositionComplete = function () {

            // performance metric
            m.t1 = new Date().getTime();
            if (m.moduleId.indexOf("module-base") < 0) {
                log.debug("Module " + m.moduleId + " took " + (m.t1 - m.t0).toFixed(4) + "ms to load", "module-base.js, line " + utils.getCurrentLineNumber(), "module-base.js");
            }

            if (m.onCompositionComplete) {
                return m.onCompositionComplete.apply(m, activateArguments || []);
            }
            else {
                return true;
            }

        }



        // private array to track CSS files loaded by the applet
        var cssPathArray = [];
        var loadedCssPathArray = [];

        m.loadCss = function (cssPath) {

            // push to an array in case of mult. css files
            cssPath = require.toUrl(cssPath);
            cssPathArray.push(cssPath);
        }

        function loadAppletCss() {

            // getting references to the head and finding the last link item
            var $head = $("head");
            var $headlinklast = $head.find("link[rel='stylesheet']:last");

            cssPathArray.forEach(function (link) {

                // checking to be sure that the current link is not already in the loaded CSS array
                if (loadedCssPathArray.indexOf(link) === -1) {

                    var linkElement = "<link rel='stylesheet' href='" + link + "' type='text/css'  media='screen'>";

                    // if there are existing link elements, put the new CSS link after -- otherwise, just add it to the head section
                    if ($headlinklast.length) {
                        $headlinklast.after(linkElement);
                    }
                    else {
                        $head.append(linkElement);
                    }

                    // add the loaded css to the loaded array to keep track of it
                    loadedCssPathArray.push(link);
                }
            });


            // empty the load queue
            cssPathArray = [];
        }

        //function unloadAppletCss() {

        //    //var previousCssPathArray = cssPathArray;

        //    setTimeout(function () {

        //        previousCssPathArray.forEach(function (path, index, object) {

        //            if (path && cssPathArray.indexOf(path) !== -1) {

        //                var cleanPath = (path.indexOf("!css") > 0) ? path.slice(4) : path;

        //                var linksToDisable = $("link[href='" + cleanPath + "']");

        //                // disable the CSS
        //                linksToDisable.prop('disabled', true);

        //                //remove the CSS
        //                linksToDisable.remove();
        //            }
        //        });


        //    }, 1000);



        // clear the CSS array before the view load event pushes on any new CSS paths
        //cssPathArray = [];
        // }




        //get object values back out of cache
        function unwrapArguments(args) {
            var unwrapped = [];

            //if values are stored on the $navigation object, we can use them directly.
            if (args.length === 0) {
                var values = $navigation.parameterValues;
                delete $navigation["parameterValues"];
                return values || [];
            }

            for (var i = 0; i < args.length; i++) {
                var value = (typeof value === "string") ? decodeURIComponent(args[i]) : args[i];
                if (typeof value === "string" && value.indexOf("cid") === 0) {
                    //assume this is a cache key, since it's a string starting with cid (string values starting with cid also get cached)

                    if (cache.hasKey(value)) {
                        value = cache.get(value); //get the cache value, but do not destroy the entry
                        //TODO: figure out how to handle the lifetime of the cache without infringing on the ability 
                        //      to use the browser back and forward buttons and maintain state.
                        //Idea:  Should be able to modify the routing mechanism so that it can pass state through the router without having to be in the URL.
                        //         Or at least the parameters could be preserved internally to the view model, passing them again before navigating back or forwards
                        unwrapped.push(value);
                    } else {
                        log.warning("Cache key is present in route, but does not exist in cache.  This may be caused by a browser page reload.");
                        if (m.onActivateError) {
                            return m.onActivateError(errorType.cachedParameterNotFound);
                        } else {
                            //by default, redirect to default route if cache key is lost (usually because of page reload)                              if (err === this.errorType.cachedParameterNotFound) {
                            return { redirect: "#/" };
                        }
                    }
                } else {
                    try {
                        //all JSON is encrypted
                        if (typeof value === "string") {
                            var decryptedValue = tea.decrypt(value, fusion.teaKey);

                            // parsing JSON from the decrypted result
                            var parsedValue = JSON.parse(decryptedValue);

                            // confirming that paresdVal.val exists before using it - otherwise use the plain value
                            if (parsedValue.val) {
                                unwrapped.push(parsedValue.val);
                            }
                            else {
                                //use plain value
                                unwrapped.push(value);
                            }
                        } else {
                            //use plain value
                            unwrapped.push(value);
                        }
                    }
                    catch (err) {
                        //use plain value
                        unwrapped.push(value);
                    }
                }
            }
            return unwrapped;

        }



        function scrollToPageMessage() {

            // Get height of fixed headers and subtract from scroll
            var headerHeight = 10;
            $(".fusion-header.fusion-fixed").each(function () {
                headerHeight += $(this).height();
            });

            if ($(".fusion-pagemessage").length) {

                if (fusion.cordova.isCordova && $(".fusion-inner-wrap").length) {
                    //$('.fusion-inner-wrap').scrollTo($(".fusion-pagemessage").position().top - headerHeight);
                    $(".fusion-pagemessage")[0].scrollIntoView();
                    $(".fusion-inner-wrap").scrollTop($(".fusion-inner-wrap").scrollTop() - headerHeight);
                }
                else {
                    $('html,body').scrollTo($(".fusion-pagemessage").offset().top - headerHeight);
                }
            } else {
                if (fusion.cordova.isCordova && $(".fusion-inner-wrap").length) {
                    $('.fusion-inner-wrap').scrollTo(0 - headerHeight);
                }
                else {
                    $('html,body').scrollTo(0 - headerHeight);
                }
            }
        }

        //validate view model and display all remaining errors
        m.validate = function (pageMessage) {
            var defaultPageMessage = "Fix errors below";
            var isValid = validation.validateViewModel(this);
            if (!isValid) {
                if (pageMessage === void 0) {
                    pageMessage = defaultPageMessage;
                }
                m.setPageMessageHtml(pageMessage);
            }
            return isValid;
        };


        // JEF 20211104 :modifed this method due to errors 
        m.clearErrors = function () {
          //  validation.clearErrors(m);                            // 20211104 -> this line errors, so is not usable

            m.setPageMessageHtml(null);                             // clearing the page message
            var errors = ko.validation.group(m, { deep: true });    // getting the current errors
            errors.showAllMessages(false);                          // KO validation way of clearing the errors
        }

        m.applyFieldMessages = function (fieldMessages) {
            for (var i = 0; i < fieldMessages.length; i++) {
                var fieldMessage = fieldMessages[i];
                var observable = this[fieldMessage.propertyName];
                if (ko.isObservable(observable) && observable.setError) {
                    observable.setError(fieldMessage.message);
                    observable.isModified(true);
                } else {
                    log.warning("Received field message for `" + fieldMessage.propertyName +
                        "`, but no such property exists as a validatable observable on this view model: " + moduleId);
                }
            }
        }

        m.setPageMessageHtml = function (message, scroll) {
            m.pageMessageHtml(message);
            if (message && (scroll !== false)) {
                scrollToPageMessage();
            }
        };

        m.setParent = function (parent) {
            //TODO: this function is obsolete
            parent.setChild(m);
        };

        m.setChild = function (child) {
            child.setPageMessageHtml = m.setPageMessageHtml;
            child.pageMessageHtml = m.pageMessageHtml;
        };






        //#region Pull to Refresh
        /*
         * JF42265 : Added 6.0.11 - pull to refresh
         * */


        m.pStart = { x: 0, y: 0 };
        m.pStop = { x: 0, y: 0 };

        function swipeStart(e) {
            try {

                if (typeof e['targetTouches'] !== "undefined") {
                    var touch = e.targetTouches[0];
                    m.pStart.x = touch.screenX;
                    m.pStart.y = touch.screenY;
                } else {
                    m.pStart.x = e.screenX;
                    m.pStart.y = e.screenY;
                }
            }
            catch (e) {
                log.error("Error in module-base, swipeStart. e : " + JSON.stringify(e));
            }
        }

        function swipeEnd(e) {
            try {

                if (typeof e['changedTouches'] !== "undefined") {
                    var touch = e.changedTouches[0];
                    m.pStop.x = touch.screenX;
                    m.pStop.y = touch.screenY;
                } else {
                    m.pStop.x = e.screenX;
                    m.pStop.y = e.screenY;
                }

                swipeCheck();
            }
            catch (e) {
                log.error("Error in module-base, swipeEnd. e : " + JSON.stringify(e));
            }

        }

        function swipeCheck() {
            try {

                var changeY = m.pStart.y - m.pStop.y;
                var changeX = m.pStart.x - m.pStop.x;
                if (isPullDown(changeY, changeX)) {
                    log.debug('Swipe Down!');

                    // note this does not call the VM lifecycle methods
                    //var router = require('durandal/plugins/router');
                    //router.activeItem().activate();

                    // location.reload();   // heavy method to do this but all VM lifecycle methods are called

                    // publish Fx pull refresh
                    
                    evnt.publish(m.events.pullToRefresh, true);
                }
            }
            catch (e) {
                log.error("Error in module-base, swipeCheck. e : " + JSON.stringify(e));
            }
        }

        function isPullDown(dY, dX) {
            // methods of checking slope, length, direction of line created by swipe action 
            return dY < 0 && (
                (Math.abs(dX) <= 100 && Math.abs(dY) >= 300)
                || (Math.abs(dX) / Math.abs(dY) <= 0.3 && dY >= 60)
            );
        }

        // setting up the listeners for anything with a touch capability
        document.addEventListener('touchstart', function (e) { swipeStart(e); }, false);
        document.addEventListener('touchend', function (e) { swipeEnd(e); }, false);

        //#endregion





    }   // END :: Module Base
    //return the factory function
    return ModuleBase;
});

