define('fusion/private/fusion-control',['require','fusion/log','fusion/log','knockout','fusion/jquery','fusion/config','fusion/utils','fusion/jqueryui','fusion/private/settings-validator'],function (require) {
    var log = require('fusion/log');

    var log = require("fusion/log");
    var ko = require('knockout');
    var $ = require("fusion/jquery");
    var $config = require("fusion/config");
    var $utility = require("fusion/utils");

    require("fusion/jqueryui"); //ensure jQueryUI is loaded before loading any controls (things like $.uniqueId() depend on jQuery UI)

    //we need to maintain a list of "fusion" control tags that are not their own control
    //this will be used so that we don't try to compile and render non-controls
    var placeholderOnlyTags = ["fusion-view", "fusion-config"];

    //customize the knockout binding provider so that it can recognize our attribute-based control syntax
    //  This works by inspecting each node, and if it is a fusion-* element, but it doesn't have a data-bind attribute,
    //      then create a data-bind attribute based on the existing attributes for the element.
    ko.bindingProvider.instance.preprocessNode = function (node) {

        //If there's a valid binding on the element, then don't try to apply ours
        if (node.nodeType === 1 /* element */ && !node.hasAttribute("data-bind")) {
            var tagNameLower = ko.utils.tagNameLower(node);

            //get the control group name
            var controlGroup = tagNameLower.substring(0, tagNameLower.indexOf('-'));

            // if it's one of our controls (or a custom control), then
            //  collect all the attributes and convert them to a 'fusioncontrol' binding
            if ($config.controlGroups.hasOwnProperty(controlGroup)) {
                convertAttributesToBinding(node);
            }
        }

    }

    //convert `settings` attribute to a `data-bind` attribute
    function convertAttributesToBinding(node) {

        var attributes = "";
        for (var i = 0; i < node.attributes.length; i++) {
            attributes += attributes ? ", " : "";
            attributes += node.attributes[i].nodeName + ": " + node.attributes[i].value;
        }
        node.setAttribute("data-bind", "fusioncontrol: {" + attributes + "}");
    }

    // Similar to angularJS' `transclude`, this binding allows a control template
    // to specify where it should include literal DOM markup that was specified 
    // between the fusion control's open and close tags.
    ko.bindingHandlers.transclude = {
        init: function (element, valueAccessor, allBindingsAccessor, controlSettings, bindingContext) {

            //make sure we have a controlSettings object that we're binding to
            if (!controlSettings.__bindingType) {
                //if not, then look up the tree until we find one.
                controlSettings = findParentControlSettings($(element));
            }


            if (controlSettings.$element.contents) {

                //add the specified elements from the control's content into the transclusion element.
                var filter = ko.unwrap(valueAccessor());
                var transcludedElements = filter ? controlSettings.$element.find(filter).toArray() : controlSettings.$element.contents().toArray();
                ko.virtualElements.setDomNodeChildren(element, transcludedElements);

                //data-bind the transcluded contents against the same context that that the control (parent) was bound against.
                var transclusionContext = findTransclusionContext(bindingContext);

                // ADDED ** v6.0.4
                // Extend the transclusion context with the binding context. This allows 'let' bindings
                // to propogate to all descendants. Without this, the 'transclude' binding is picked up
                // at control initialization, so any 'let' bindings inside the control template don't get
                // picked up as part of transclusion context.
                // 
                // ** NOTE **
                // We extend bindingContext with transclusionContext so that transclusionContext 
                // overrides anything in bindingContext. If we do it the other way around, the context
                // isn't right.
                //transclusionContext = bindingContext.extend(transclusionContext);

                ko.applyBindingsToDescendants(transclusionContext, element);
            }

            return { controlsDescendantBindings: true };
        }
    }
    ko.virtualElements.allowedBindings.transclude = true;

    //similar 'transclude' his binding allows a control template
    //  to specify where it should include literal DOM markup that was specified 
    //  between the fusion control's open and close tags.
    //The difference with this binding is that it will not switch the binding context.
    ko.bindingHandlers.include = {
        init: function (element, valueAccessor, allBindingsAccessor, controlSettings, bindingContext) {

            //make sure we have a controlSettings object that we're binding to
            if (!controlSettings.__bindingType) {
                //if not, then look up the tree until we find one.
                controlSettings = findParentControlSettings($(element));
            }


            if (controlSettings.$element.contents) {

                //add the specified elements from the control's content into the transclusion element.
                var filter = ko.unwrap(valueAccessor());
                var transcludedElements = filter ? controlSettings.$element.find(filter).toArray() : controlSettings.$element.contents().toArray();
                ko.virtualElements.setDomNodeChildren(element, transcludedElements);

                //data-bind the transcluded contents against the same context that that the control (parent) was bound against.
                //var transclusionContext = findTransclusionContext(bindingContext);
                //ko.applyBindingsToDescendants(transclusionContext, element);
            }

            //return { controlsDescendantBindings: true };
        }
    }
    ko.virtualElements.allowedBindings.include = true;

    //this binding allows us execute a 'compose' binding, with the $parentContext set to the transclusionContext.
    //This allows developer's templates to use $parent in a natural way, where $parent references the parent of the template,
    //  relative to the developer's markup, disregarding the child context that occurs inside a control binding.
    ko.bindingHandlers.composeInControl = {
        init: function (element, valueAccessor, allBindingsAccessor, controlSettings, bindingContext) {

            var transclusionContext = findTransclusionContext(bindingContext);

            //create a child context so that we can continue using the same $data object
            var childBindingContext = transclusionContext.createChildContext(bindingContext.$data);

            //make current $index available, in case it's needed.
            //This is similar to what KO does in createChildContext() when setting up the hierarchy
            childBindingContext.$index = bindingContext.$index;

            ko.applyBindingsToNode(element, { compose: valueAccessor() }, childBindingContext);

            return { controlsDescendantBindings: true };
        }
    }
    ko.virtualElements.allowedBindings.composeInControl = true;

    function findTransclusionContext(bindingContext) {
        while (bindingContext.$parentContext && !bindingContext.transclusionContext) {
            return findTransclusionContext(bindingContext.$parentContext);
        }
        return bindingContext.transclusionContext;
    }


    function getViewModel(controlSettingsOrViewModel) {
        // returns .viewModel or controlSettingsOrViewModel
        return (controlSettingsOrViewModel && controlSettingsOrViewModel.viewModel) || controlSettingsOrViewModel;

        //alternate syntax: ( doesn't rely on truthiness cleverness)
        //if (controlSettingsOrViewModel) {
        //    return controlSettingsOrViewModel.viewModel;
        //}
        //return controlSettingsOrViewModel;
    }


    // Main fusion control binding
    ko.bindingHandlers.fusioncontrol = {
        /*
            element = fusion-textbox
            valueAccessor = {value: firstName, labelText: 'Name'} 
            allBindingsAccessor = all bindings on the element, including fusioncontrol binding
            controlSettingsOrViewModel = KO's view model, equivalent to bindingContext.$data -- for the current context ( not necessarily developer's VM).  
            bindingContext = the KO binding context, where $root, $parent, etc. comes from
        */
        init: function (element, valueAccessor, allBindingsAccessor, controlSettingsOrViewModel, bindingContext) {

            var $element = $(element);
            var bindingType = $element.prop("tagName").toLowerCase();

            //don't try to process placeholder-only tags
            if (placeholderOnlyTags.indexOf(bindingType) > -1) {
                return;
            }

            //disallow any bindings other than the 'fusioncontrol' binding
            var allBindings = allBindingsAccessor();
            for (binding in allBindings) {
                if (allBindings.hasOwnProperty(binding)) {
                    if (binding != "fusioncontrol") {
                        log.error("The 'fusioncontrol' binding cannot be used in conjunction with any other binding.");
                    }
                }
            }

            var controlModuleId = getControlModuleId(bindingType);      // e.g. fusion/ui/controls/fusion-textbox
            getControlFunction(controlModuleId)                                 // getting control's JS, i.e. control view model
            .done(function (control, allBindings) {
                //default the control template ( HTML) to same name as control
                var controlTemplatePath = getControlTemplateModuleId(bindingType);

                //if control is extended, we might need to use template of "base", or extended, control
                if (control.extendOptions) {
                    if (control.extendOptions.template) {
                        //use template from the extended control
                        controlTemplatePath = getControlTemplateModuleId(control.extendOptions.template);
                    }
                }

                //get the template markup
                getControlTemplate(controlTemplatePath)
                .done(function (controlTemplate) {
                    initializeControl(control, controlTemplate);    // control can be initialized/used now b/c we have template and view model
                });
            });



            //initialize and execute control instance  -- marries HTML and JS and applies bindings
            function initializeControl(control, controlTemplate) {

                var $templateMarkup = $(controlTemplate);       // control's block of HTML text - making it a jQ object

                //process settings - validates and provides defaults
                var controlSettings = valueAccessor();      // self documenting LOL
                controlSettings.__bindingType = bindingType;    // e.g. fusion-textbox, derived from <fusion-textbox>.  Saving here for later use

                var $root = $templateMarkup.first();    // first element in HTML
                //this will add the control name as a class on the top-level element
                $root.addClass(controlSettings.__bindingType);


                if (!control.settingsDefinition) {
                    throw new Error(controlSettings.__bindingType + "-- control must have a settings definition");
                }
                var settingsDefinition = control.settingsDefinition;
                settingsDefinition.instanceCount = settingsDefinition.instanceCount || 0;   // instances of this control type of applet's lifetime/page load -- adding this count to the singleton instance of the control definition

                validateSettingsAndDefinitions(controlSettings, settingsDefinition);        // ensuring the control's settings defs are met by what the developer specified

                //(TODO) MIght be able to delete this function - see what clean up is required
                validateContent(controlSettings, settingsDefinition, $element);



                // adding cssClass class name to the element if it is not empty, null, or undefined
                if (controlSettings.cssClass !== "" && controlSettings.cssClass !== null && controlSettings.cssClass !== undefined) {
                    $root.addClass(controlSettings.cssClass);
                }


                // adding tabIndex attribute to the element if it is specified
                if (controlSettings.tabIndex !== "" && controlSettings.tabIndex !== null && controlSettings.tabIndex !== undefined) {
                    $root.find('[data-fusion="tabstop"]').each(function () {
                        $(this).attr('tabindex', controlSettings.tabIndex);
                    });
                }





                // var used to ensure uniqueness of the IDs associated with fields.  Each field needs it's own unique id.
                controlSettings.instanceId = controlSettings.__bindingType + settingsDefinition.instanceCount++;        // e.g. fusion-textbox0.  Useful for label for="" attributes

                //these properties are needed by the transclude binding, and may also be needed within the control's function itself
                controlSettings.viewModel = getViewModel(controlSettingsOrViewModel);  // looking for the view model that control is bound against-ish -- allows developer to bind something transcluded to the view model they know about.  Allows us to use the developer's view model when binding transcluded stuff
                controlSettings.$element = $element;

                //validate the values that are specified on the control -- runs the contol's validate function on the definition with current binding vals
                var settingsWithValidator = new (require("fusion/private/settings-validator"))(controlSettings, bindingType.toUpperCase(), controlSettings.viewModel.__moduleId__);
                control.validateValues && control.validateValues(settingsWithValidator);    // if it exists, run it LOL

                //create a child context so that we can bind the control's template to the control's settings object
                var childBindingContext = bindingContext.createChildContext(controlSettings);

                //save a reference to the current context so the 'transclude' binding we can easily find it to bind transcluded content.
                //Transcluded content should be bound to the same context that the fusion control element is bound to, 
                //  and not the control template's context.
                childBindingContext.transclusionContext = bindingContext;

                //get the parent element, if any, that is bound to a control settings object
                childBindingContext.$parentControl = findParentControlSettings($element);

                //call beforeBind - in the control definition
                control.beforeBind && control.beforeBind($templateMarkup, controlSettings, childBindingContext, $element);

                //bind the control to the control template - happens on a per control basis
                ko.applyBindings(childBindingContext, $templateMarkup[0]);

                //call afterBind
                control.afterBind && control.afterBind($templateMarkup, controlSettings, childBindingContext, $element);

                //now replace the original fusion element with the modified markup.
                // (TODO) :: could make this .append and keep the <fusion...> tag.  Likely impact will be style related w positional selectors but code would be a bit more readable in that original developer intent is preserved
                $element.replaceWith($templateMarkup);

                //remove and clean the original fusion control element - KO world clean up, e.g. bindings or stuff KO added is removed 
                ko.removeNode(element);

                //call afterDomInsert
                // Note the$templateMarkup var is repeated as last param.  THis is due to replacing element rather than appending.  If we had appended, could have just passed in $element.  Might be need for clean up in the future
                control.afterDomInsert && control.afterDomInsert($templateMarkup, controlSettings, childBindingContext, $templateMarkup);
                controlSettings.markup($templateMarkup);

                //call controlComplete
                //control.controlComplete && control.afterDomInsert($templateMarkup, controlSettings, bindingContext, $element);
            }


            // KO thing : needed b/c we are creating a library of reusuable controls.  Like a stop propagation on KO bindings - keeps KO from binding any 
            //  child elements for this control. Setting tells KO that we will handle binding inside the control ( this is the ko.applyBindings call above ) so
            //  we can bind using a differnt/custom context
            return { controlsDescendantBindings: true };
        }
    };

    function findParentControlSettings($element) {
        var parentFusionElement;
        while (!parentFusionElement) {
            $element = $element.parent();
            if (!$element[0]) {
                return void 0;
            }
            var dataFor = ko.dataFor($element[0]);
            if (dataFor && dataFor.__bindingType) {
                return dataFor;
            }
        }
        return void 0;
    }

    //get the requireJS moduleId of the template for the specified contorl
    function getControlTemplateModuleId(controlName) {
        var moduleId = "text!" + getControlModuleId(controlName) + ".html";
        return moduleId;
    }

    //get the requireJS moduleId for the specified control
    function getControlModuleId(controlName) {
        var controlPath = getControlPath(controlName);
        var moduleId = controlPath + controlName;       // this is the requireJS module path
        return moduleId;
    }

    //get the folder that contains the control module + template for this control.  This how we can support custom controls in other projects, i.e. mod common 
    function getControlPath(controlName) {
        var controlGroup = controlName.substring(0, controlName.indexOf('-'));      // by convention control names are hyphenated - first part is the control group 
        var controlPath = $utility.url.ensureTrailingSlash($config.controlGroups[controlGroup]);  // finding path to the control based on control group name
        return controlPath;
    }

    //get control function for specified requireJS path
    function getControlFunction(controlPath) {
        var dfd = $.Deferred();
        require([controlPath], function (controlFunction) {     // since this require is dynamic, control depenedencies are not picked up automatically by require and therefore have to be explicity listed in the optimizer to be included
            dfd.resolve(controlFunction);
        }, function (err) {
            log.error(err.requireType + " error retrieving control function " + controlPath + "  Error is below:");
            throw err;
        });
        return dfd.promise();
    }

    //get template markup for specified requireJS path
    function getControlTemplate(templatePath) {
        var dfd = $.Deferred();
        require([templatePath], function (controlTemplate) {
            dfd.resolve(controlTemplate);
        }, function (err) {
            log.error(err.requireType + " error retrieving control template " + templatePath + "  Error is below:");
            throw err;
        });
        return dfd.promise();
    }


    function getGlobalSettingsDefinition() {
        //global settings have to be defined here, or 
        //  an exception will be thrown when the specified settings are validated
        return {
            //isVisible: { isRequired: false, isLive: true, defaultValue: true },
            //isEnabled: { isRequired: false, isLive: true, defaultValue: true },
            cssClass: { isRequired: false, isLive: false, defaultValue: '' },
            tabIndex: { isRequired: false, isLive: false, defaultValue: '' },
            //if showValidation=false, then validation messages aren't displayed as part of the control.
            //  This is utilized by the control-behavior-binding
            showValidationMessage: { isRequired: false, isLive: false, defaultValue: true },
            markup: { isRequired: false, isLive: true, defaultValue: null }
        };
    }

    //correct settings object to have correct case for property 
    function fixPropertyCasing(prop, settings) {
        // prop:     the correctly cased property name
        // settings: the object that we will check for a property
        //              by this name, using a case-insensitive match, and rename it
        //              to the have the correct character case
        if (settings.hasOwnProperty(prop)) {
            //exit early since the property is already on the settings object with correct case
            return;
        }
        var lowerProp = prop.toLowerCase();
        //iterate through each key on the settings object (settings object is the 
        //  collection of properties that was provided by the developer on the control instance
        for (var uncasedProp in settings) {
            //make sure it's not a property on the settings' prototype object
            if (settings.hasOwnProperty(uncasedProp)) {
                //if property is same name, excluding case, then correct it.
                if (uncasedProp.toLowerCase() === lowerProp) {
                    //set property using correct case, and don't worry about overwriting existing property
                    settings[prop] = settings[uncasedProp];
                    //remove the old property
                    delete settings[uncasedProp];
                    //exit here as soon as we found the property and renamed to correct case
                    return;
                }
            }
        }
    }

    function validateSettingsAndDefinitions(settings, settingsDefinition) {


        // extend settingsDefinition with globalSettings so settings validation will pass, i.e. cssClass, etc.
        // Note, settingsDefinition is being re-extended each time, so this is a possible inefficiency  (TODO)
        settingsDefinition = ko.utils.extend(settingsDefinition, getGlobalSettingsDefinition());


        //  set defaults and validate the required
        for (var prop in settingsDefinition) {
            if (settingsDefinition.hasOwnProperty(prop)) {

                //fix properties casing.  Because we know that settingsDefinition object defines all the possible
                //  properties, we can use it to convert mis-matched property casing on the settings object, i.e. developer puts labeltext vs. labelText
                fixPropertyCasing(prop, settings);

                //if developer did not specify the property, throw error if it's required.
                //otherwise, use the default value
                if (!settings.hasOwnProperty(prop)) {
                    if (settingsDefinition[prop].isRequired === true) {
                        //property is required, but not specified, so throw an error
                        log.error("Setting '" + prop + "' is required, but missing for the " + settings.__bindingType + " control.");
                    } else {
                        //property is missing, so use the defaultValue, if any
                        settings[prop] = settingsDefinition[prop].defaultValue;
                    }
                }



                // JEF 20160927 :: Commented out this code working on ESRI map control - didn't have a good reason to not allow functions 
                //                  and map control needs the ability to 

                //                  Equivalent to bindingContext.$datahave a view model method associated with control property.

                //don't allow properties to be functions other than observables and ko.command
                //if (typeof settings[prop] === "function") {
                //    if (ko.isObservable(settings[prop])) {
                //        //observable functions are allowed
                //    } else if (settings[prop].canExecute && settings[prop].execute) {
                //        //assume if it has these methods it is a ko.command, which is allowed
                //    }
                //    else {
                //        //any other function types are not allowed
                //        log.error("Invalid setting '" + prop + "'.  Functions are not allowed");
                //    }
                //}





                //reconcile isLive with the supplied value -- guarantees an observable if isLive is true
                if (settingsDefinition[prop].isLive) {

                    //set the liveType attribute for the setting (should either be ko.observable or ko.observableArray).
                    //  set the default to ko.observable
                    settingsDefinition[prop].liveType = settingsDefinition[prop].liveType || ko.observable;

                    //If the property is specified as isLive, then make sure we have an observable.
                    if (!ko.isObservable(settings[prop])) {
                        settings[prop] = settingsDefinition[prop].liveType(settings[prop]);         // if prop not observable, make it an observable of the right kind, i.e. obsevable or observArray,depending on liveType
                    }
                }
                else {
                    //Observables should always be supported, even if it's just for one-time binding.
                    //  so, if property is observable, but isLive === false, then we'll automatically unwrap the observable here.
                    settings[prop] = ko.unwrap(settings[prop]);
                }

            }
        }


        //(TODO) : at some point this can be removed.  Need to note in release notes that this is breaking but prop rarely used
        //temporary support for obsoleted setting
        if (settings.hasOwnProperty("showValidation")) {
            log.warning("Property 'showValidation' has been renamed to 'showValidationMessage'  Please use this new name immediately.");
            settings.showValidationMessage = settings.showValidation;
        }

        //inspect all user-supplied settings
        for (var prop in settings) {
            //make sure there's not any extra properties defined that are outside of what the control supports
            if (prop.indexOf("__") != 0 && settings.hasOwnProperty(prop)) {
                if (!settingsDefinition.hasOwnProperty(prop)) {
                    // logging warning here to assist developer during dev rather than throw an actual error
                    log.warning("Setting '" + prop + "' is not allowed for " + settings.__bindingType + " control.");
                }
            }

        }

    }


    // (TODO) :: Needs to be cleaned up or deleted - not sure how this used anymore.  Orig intent was to ensure that a control has content if the isCOntent was true.  Content should be ignored if not used but no error thrown
    function validateContent(controlSettings, settingsDefinition, $element) {

        //find setting used for content
        var contentSetting = null;
        for (var prop in settingsDefinition) {
            if (settingsDefinition.hasOwnProperty(prop)) {      //make sure the property is a first-class property on the object
                if (settingsDefinition[prop].isContent === true) {
                    if (contentSetting != null) {
                        throw new Error("Invalid setting '" + prop + "' for element " + controlSettings.__bindingType + ".  Only one defined setting can have 'isContent=true'");
                    }
                    contentSetting = prop;
                }
            }
        }

        //(TODO) : might be able to delete these vars , but search code to be sure they are not used/needed
        //get the content directly from the element's html content
        controlSettings.__hasDirectContent = $element.contents().length > 0;
        controlSettings.__hasContentSetting = controlSettings.hasOwnProperty(contentSetting);

        controlSettings.__hasContent = (controlSettings.__hasDirectContent || controlSettings.__hasContentSetting);

        if (controlSettings.__hasDirectContent && controlSettings.__hasContentSetting) {
            //make sure content and text are not both set
            log.error("Invalid setting '" + contentSetting + "'.  Element '" + controlSettings.__bindingType +
                "' already has direct html content.  Content cannot be set more than once.");
        }
    }

    //no return needed

}); // END :: define statement


;
