define('fusion/ui/controls/fusion-mfa-verify',[
    "fusion/fusion.control"
], function (ControlFactory) {
    return ControlFactory.control(function ($, ko, $log, $utility) {
        var c = this;

        c.settingsDefinition = {
            value: { isLive: true, defaultValue: '' },
            numberOfCodeDigits: { isLive: false, defaultValue: 6 },
            isValueReady: { isLive: true, defaultValue: false },
          /*isAlphaNumericOnly: { isLive: false, defaultValue: true }, */
            clearCode: { isLive: true, defaultValue: false }
        };


        c.validateValues = function (settings) {
            // checking for direct content
            if (settings.__hasDirectContent) {
                var msg = "fusion-mfa-code does not support direct content";
                $log.warning(msg);
            }
        }

        c.beforeBind = function ($markup, settings, bindingContext, $element) {

            // digit array defined and allocated - note subscribing to this array was problematic
            settings.dArray = ko.observableArray(Array.from({ length: settings.numberOfCodeDigits }));

            // setting some initialization flags
            settings.isDigitArrayInit = ko.observable(false);
            settings.isDigitArrayDirty = ko.observable(false);

            // clearCode subscribes to VM flag to reset the control
            settings.clearCode.subscribe(function(val){
                if (val) {
                    // getting  inputs and clearing by clicking on first input element
                    var inputs = $markup.find('.input-pin');
                    $(inputs[0]).click().mousedown().mouseup();

                    // resetting the clearCode observable 
                    settings.clearCode(false);
                }
            });

        }

        c.afterBind = function ($markup, settings, bindingContext, $element) {
        }


        c.afterDomInsert = function ($markup, settings, bindingContext) {

            // remove disabled attr on first input
            $first = $($markup.find('.input-pin')[0]);
            $first.removeAttr('disabled').focus();          // removing disabled attr on first input and setting focus there

            // setting up event handlers
            var $inputs = $($markup.find('.input-pin'));

            $inputs.on('keyup', _handleChange);
            $inputs.on('change', _handleChange);
            $inputs.on('mouseup', _clearToEnd);

            $inputs.first().removeAttr('disabled').focus();

            //#region helper functions

            function _moveNext($t) {
                var $next = $t.next('input');

                if (Number.isInteger(parseInt($next.val()))) {   // if a number is in the next input, then skip to the following one
                    _moveToNext($next);
                }
                else {
                    $next.prop('disabled', false).focus();
                }
            }

            function _movePrev($t) {
                // disable the current input
                $t.prop('disabled', true).val('');        // disabling and clearing

                //focus on the previous input
                $t.prev('input').focus().val('');
            };

            function _resetDigitsToEnd(startPosition, $current) {
                for (var i = startPosition; i < settings.numberOfCodeDigits; i++) {
                    settings.dArray()[i] = undefined;       // clearing array position
                    $current.val('');                       // clearing input
                    $current = $current.next('input');      // moving to the next position
                    $current.attr('disabled', 'true');      // setting disabled 
                }
                $log.trace("Array reset from position " + startPosition + " is complete");

                settings.value.isModified(false);       // clearing any existing errors on the field if user clicks into a digit
            };

            function _clearToEnd(e) {
                if (settings.isDigitArrayDirty()) {        // eliminates initial click into first field
                    var position = e.target.getAttribute("tabindex");
                    _resetDigitsToEnd(position, $(this));
                }
                else {
                    settings.isDigitArrayDirty(!isNaN(settings.dArray()));  // after first click array should be considered edited as long as there are digits
                }
            }

            // since subscription to the obs array was not behaving as expected, doing this in a more manual way
            function _checkArrayReady() {
                var isReady = true;

                // looping through digits and using bit logic to determine if array is ready
                for (var i = 0; i < settings.dArray().length; i++) {
                    isReady = isReady && !isNaN(settings.dArray()[i]);
                }

                return isReady;
            }

            function _updateArray(position, value) {
                settings.dArray()[position] = value;

                $log.trace("in UpdateArray, Array changed : " + settings.dArray().join(''));

                if (_checkArrayReady()) {
                    $log.trace("fusion-mfa :: digits are complete : " + settings.dArray().join(''));

                    // emitting when all digits are ready
                    settings.value(settings.dArray().join(''));
                    settings.isValueReady(true);
                }
            }

            function _performCharCheck(key, $current) {

                var rtnVal = true;

                // perform initial check for non-numberic chars un less it is a backspace
                if (key !== 8 && key !== 46) {

                    var reg = new RegExp('^[0-9]$');
                    var result = reg.test($current.val());

                    if (!result) {                // indicates a non-numeric val entered and needs to be handled
                        //strip the current char out
                        $current.val(null);

                        // log a warning
                        $log.warning("Non-numberic entries are not accepted - key val entered : " + key + ", " + String.fromCharCode((96 <= key && key <= 105) ? key - 48 : key));

                        rtnVal = false;
                    }
                }

                return rtnVal;
            }

            //#endregion helper functions


            // main change handler method
            function _handleChange(e) {

                var key = e.keyCode || e.charCode;

                var $prev = $(this).prev('input');
                var $current = $(this);

                // check for non numeric chars
                if (_performCharCheck(key, $current)) {
                    // if this is a backspace
                    if (key === 8 || key === 46) {
                        if (parseInt($current.attr("tabindex")) === settings.numberOfCodeDigits - 1) {   // at the last digit
                            settings.value.isModified(false);       // clearing any existing errors on the field if user clicks into a digit
                            _updateArray(parseInt($current.attr("tabindex")), undefined);       // clearing end digit
                            _movePrev($current);

                            _updateArray(parseInt($current.attr("tabindex")) - 1, undefined);   // clearing previous digit like normal
                            _movePrev($current);

                        }
                        else if (parseInt($current.attr("tabindex")) !== 0) {         // making sure we are not at the first spot
                            _updateArray(parseInt($current.attr("tabindex")) - 1, undefined);
                            _movePrev($current);
                        }
                    }
                    // otherwise  assume a proper number input
                    else {
                        if (key) {  // acts a debounce to prevent mult passes through this logic
                            if (!settings.isDigitArrayDirty()) {
                                settings.isDigitArrayDirty(true);
                            }
                            _updateArray(parseInt($current.attr("tabindex")), parseInt($current.val()));

                            _moveNext($current);
                        }
                    }
                }
            };

            // set the initialized flag
            settings.isDigitArrayInit(true);

        }
    });
});
