define('fusion/ui/controls/fusion-toggle',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function ($, ko, $log, $utility) {
        var c = this;

        // central message definitions
        var msgs = {
            genericReversal: "fusion-toggle : no function to execute, treating as a simple toggle",
            promiseReject: "view model promise rejected",
            promiseFalse: " view model promise resolved false",
            settingsCheckValue: "fusion-toggle value attribute must be a boolean",
            settingsCheckFunction: "fusion-toggle callBack must be a function",
            errorBadPromise: "Unknown promise type in fusion-toggle",
            labelTextConditionalRequire: "labelText is required if labelTextPosition attribute is specified"
        };

        var labelPositions = {
            "left": "left",
            "right": "right"
        };
        var stateDescriptorSets = {
            "onoff": "onoff",
            "yesno": "yesno"
        };
        var stateDescriptorSetValues = {
            "onoff": ["off", "on"],
            "yesno": ["no", "yes"]
        };

        // helper function for obj/array checks
        function _convertObjToArray(objArray) {
            var returnArr = [];

            for (var key in objArray) {
                if (objArray.hasOwnProperty(key)) {
                    returnArr.push(objArray[key]);
                }
            }

            return returnArr;
        }




        c.settingsDefinition = {
            value: { isLive: true, defaultValue: false },
            callBack: { isLive: false },
            labelText: { isLive: true },
            labelTextPosition: { isLive: false, defaultValue: labelPositions.left },
            showStateDescriptor: { isLive: false, defaultValue: false },
            stateDescriptorSet: { isLive: false, defaultValue: stateDescriptorSets.onoff }
        };


        c.validateValues = function (settings) {

            function _helperCheckBoolenVal(val) {
                return (val !== undefined && !(typeof val === "boolean" || (val === "true" || val === "false")));
            }


            // checking for boolean or a string version of a boolean - note that undefined indicates an uninitialized observable
            var x = ko.unwrap(settings.value);
            if (_helperCheckBoolenVal(x)) {
                // determining the instance ID
                var instanceID = this.settingsDefinition.instanceCount - 1;

                throw new Error(msgs.settingsCheckValue + " : fusion-toggle" + instanceID);
            }

            // ensuring callback is actually a function
            if (settings.callBack && typeof settings.callBack !== "function") {
                throw new Error(msgs.settingsCheckFunction);
            }

            // ensure showStateDescriptor is a bool


            //checking labelTextPosition
            if (settings.labelTextPosition) {
                //if (!ko.unwrap(settings.labelText)) {   // ensure labelText is specified if labelTextPosition is specified
                //    throw new Error(msgs.labelTextConditionalRequire + " : fusion-toggle" + instanceID);
                //}

                // ensure label position is valid
                settings("labelTextPosition").throwIfNot().isOneOf(_convertObjToArray(labelPositions));
            }

            // checking indicator sets
            if (settings.stateDescriptorSet) {

                // ensure setting is valid
                settings("stateDescriptorSet").throwIfNot().isOneOf(_convertObjToArray(stateDescriptorSets));
            }

        }


        c.beforeBind = function ($markup, settings, bindingContext, $element) {

            //#region helper functions for beforeBind
            // helper to set the stateDescriptor label
            function _setStateDescriptor() {
                if (settings.showStateDescriptor) {
                    var rtn = [];
                    switch (settings.stateDescriptorSet) {
                        case stateDescriptorSets.onoff:
                            rtn = stateDescriptorSetValues.onoff;
                            break;

                        case stateDescriptorSets.yesno:
                            rtn = stateDescriptorSetValues.yesno;
                            break;
                        default:
                            var msg = "Unknown stateDescriptor set in : fusion-toggle" + instanceID;
                            $log.error(msg);
                    }
                }

                return rtn;
            }
            //#endregion helper functions for beforeBind



            settings.toggleIsBusy = ko.observable(false);           // controls the busy indicator on the toggle switch
            settings.hasRerverted = ko.observable(false);           // debounce for when toggle is reverting

            // setting up a computed to track previous value of toggle for scenarios where we need to revert
            settings.previousValue = ko.computed(function () {
                return !settings.value();
            });


            settings.labelClass = ko.pureComputed(function () {
                return settings.labelTextPosition === labelPositions.left ? "labelLeftClass" : "labelRightClass";
            });

            settings.internalStateDescriptor = ko.observable(_setStateDescriptor());

        }


        c.afterBind = function ($markup, settings, bindingContext, $element) { }


        c.afterDomInsert = function ($markup, settings, bindingContext) {

            //#region helper functions
            function revertToPreviousState(resetReason) {
                // checking for the debounce to avoid performing this multiple times
                if (!settings.hasRerverted()) {
                    settings.hasRerverted(true);                // setting the debounce switch if first time through
                    $log.debug("Resetting fusion-toggle value due to : " + resetReason);
                    settings.value(settings.previousValue());   // reverting value to previous value
                }
                else {
                    // indicates this was a bounce pass, so resetting the reverted flag
                    settings.hasRerverted(false);
                }
            }

            // helper function to abstract promise clean up functionality
            function _helperFinalizeCleanup() {
                settings.toggleIsBusy(false);
            }


            // helper function to clean up promises - note that native promise route requires returning a promise to avoid uncaught in promise error
            function executePromiseCleanup(isPromise) {
                if (isPromise) {
                    return new Promise(function (resolve) {
                        resolve(_helperFinalizeCleanup());
                    });
                }
                else {
                    _helperFinalizeCleanup();
                }

            }

            // helper function to centrally handle a bad promise scenario
            function handleBadPromise() {
                $log.error(msgs.errorBadPromise);
                throw msgs.errorBadPromise;
            }


            // helper method to abstract callback THEN method
            function _promiseHelper(promiseResolveVal) {
                // as long as the promise resolves JS truthy, all is well, otherwise revert value
                if (!promiseResolveVal) {       
                    revertToPreviousState(msgs.promiseFalse)
                }
            }


            //#endregion




            //#region initializing 

            settings.value($utility.tryParseBool(ko.unwrap(settings.value)));

            //#endregion


            // handing value change
            settings.value.subscribe(function (newVal) {

                // ensuring newVal is a bool and calling the specified viewmodel callback
                settings.toggleIsBusy(true);

                // do not execute the callback in scenario where the toggle is reverting to orig value, i.e. promise rejection or failure
                if (!settings.hasRerverted()) {
                    // checking to see if callback is a function
                    if (typeof settings.callBack === "function") {

                        // handling the callback from view model
                        var cb = settings.callBack();               // assign the callback execution to var so we can examine it 

                        //ensuring that cb is OK to work with
                        if (cb) {
                            // checking callback for a Promise
                            if (cb.then && typeof cb.then === "function") {


                                $log.trace("fusion-toggle : Executing callback");

                                // checking for native promise
                                if (cb.finally) {
                                    cb.then(_promiseHelper)
                                        .catch(function () {
                                            revertToPreviousState(msgs.promiseReject);
                                        })
                                        .finally(function () {
                                            executePromiseCleanup(true);
                                        });
                                }
                                // checking for $data promise
                                else if (cb.always) {
                                    cb.then(_promiseHelper)
                                        .fail(function () {
                                            revertToPreviousState(msgs.promiseReject);
                                        })
                                        .always(function () {
                                            executePromiseCleanup();
                                        });
                                }
                                else {
                                    handleBadPromise();
                                }
                            }
                            else {
                                handleBadPromise();
                            }
                        }
                        else {
                            handleBadPromise();
                        }

                    }
                    else {
                        // if no function to execute, then treat as a simple toggle.  Swap the value and turn off toggle busy
                        $log.trace("fusion-toggle : no callback method dected, treating as simple toggle");
                        settings.toggleIsBusy(false);
                    }

                }
                else {
                    settings.hasRerverted(false);
                }
            });

        }

    });
});
