define('fusion/ui/controls/fusion-checkbox',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function ($, ko, $log, $utility) {
        var c = this;

        c.settingsDefinition = {
            isChecked: { isLive: true },
            value: { isLive: true },
            labelText: { isLive: true },
            isEnabled: { isLive: true, defaultValue: true },
            isToggleMode: { isLive: false, defaultValue: false }
        };

        c.validateValues = function (settings) {

            if (!settings.__hasDirectContent && !settings.labelText()) {
                throw new Error("fusion-checkbox must have either content or labelText");
            }

            if(settings.isToggleMode) {
                $log.debug("isToggleMode has been deprecated for fusion-checkbox, reverting to normal checkbox. Consider using fusion-toggle instead");
            };
        }

        c.beforeBind = function ($markup, settings, bindingContext, $element) {
            // safely ensuring a boolean
            settings.isToggleMode = $utility.tryParseBool(settings.isToggleMode);
        }

        c.afterBind = function ($markup, settings, bindingContext, $element) {

        }

        c.afterDomInsert = function ($markup, settings, bindingContext) {

        }

    });
});
